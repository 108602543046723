import { observer } from "mobx-react";
import React from "react";
import keyboardJS from "keyboardjs";
import type SequencerUIState from "src/model/control/SequencerUIState";
import type Channel from "src/model/channel/Channel";
import type ChannelSequenceCell from "../../model/sequence/ChannelSequenceCell";
import Button from "../misc/Button";

interface Props {
  index: number;
  cell: ChannelSequenceCell;
  keycode: string;
  videoDuration: number | undefined;
  uiState: SequencerUIState;
  channel: Channel;
}

const ChannelSequenceCellUI: React.FunctionComponent<Props> = props => {
  const { cell, videoDuration, keycode, uiState, channel, index } = props;

  /*

    GATE Taste
    --> Ist GATE Funktion ON bestimmt die Länge des Drückens einer Cell Taste, wann der Note-Off Command also STOP gesendet wird. Ist GATE OFF gibt es keinen STOP Command und der Player läuft solange weiter bis ein neuer Note-On Command also PLAY gesendet wird.

    --> LIVE: ON und REC: OFF === DEFAULT MODUS
     Wenn man die Cell Tasten drückt, wird sofort PLAY Command gesendet. Je nach GATE ON oder OFF wird es weiter bis zum nächsten Tastendruck gespielt oder bei KeyRelease ein STOP Command gesendet.

    --> LIVE: ON und REC: ON und PLAYING
     Wenn man die Cell Tasten drückt wird sofort PLAY command gesendet und aufgezeichnet: Zeit im Loop und aktivierte Cell. Alle aufgenommenen Tasten werden in allen kommenden Loops wieder abgespielt.

     Wenn der REC Button gedrückt wird, startet die Aufzeichnung erst für den nächsten Loop. Ist der Loop in dem aufgezeichnet wird abgeschlossen, wird REC wird auf OFF gesetzt.

    --> LIVE: OFF und REC: ON
     Man befindet sich im "Step Edit" Mode. Wenn man die Cell Tasten drückt, setzen sie die für die nächsten Loops zu startenden Events. Der GATE Modus ist hier irrelevant.

    --> LIVE: OFF und REC: OFF
    Existiert nicht.

    */

  keyboardJS.bind([`${keycode} + n`], () => {
    decreaseCue();
  });

  keyboardJS.bind([`${keycode} + m`], () => {
    incrementCue();
  });

  const { startTimeStamp } = cell.cue;

  const incrementCue = () => {
    const newStartTimeStamp = startTimeStamp + 0.1;
    cell.setStartTimeStamp(parseFloat(newStartTimeStamp.toFixed(2)));
  };

  const decreaseCue = () => {
    const newStartTimeStamp = startTimeStamp - 0.1;
    cell.setStartTimeStamp(parseFloat(newStartTimeStamp.toFixed(2)));
  };

  if (!videoDuration) {
    return <>Loading</>;
  }

  const handleClick = () => {
    cell.setActive(!cell.isActive);
  };

  return (
    <Button
      variant="cell"
      handleClick={handleClick}
      title={keycode}
      isActive={cell.isActive}
      isPlaying={index === uiState.getActiveCellIndexForChannel(channel)}
      timestamp={cell.cue.startTimeStamp}
    />
  );
};
export default observer(ChannelSequenceCellUI);
