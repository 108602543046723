import { makeAutoObservable } from "mobx";
import type Cue from "../cue/Cue";
import type { VideoData } from "./VideoData";
import { assertBeatCountValid } from "../misc/assertBeatCountValid";
import ChannelSequence from "../sequence/ChannelSequence";
import type { PlayEvent } from "../play/PlayEvent";

export default class Channel {
  public readonly channelNumber: number;

  public channelSequence: ChannelSequence | undefined;

  private mVideoData: VideoData | undefined;

  private mBeatCount: number;

  constructor(channelNumber: number, beatCount: number) {
    this.mBeatCount = beatCount;
    this.channelNumber = channelNumber;
    makeAutoObservable(this);
  }

  public setVideo(videoData: VideoData | undefined): void {
    this.mVideoData = videoData;
    this.createCueData();
  }

  public getBeatCount() {
    return this.mBeatCount;
  }

  public setBeatCount(beatCount: number) {
    assertBeatCountValid(beatCount);

    this.mBeatCount = beatCount;
    if (this.channelSequence) {
      this.channelSequence.updateBeatCount(beatCount);
    }
  }

  private createCueData() {
    const { videoData, mBeatCount } = this;

    if (!videoData) {
      this.channelSequence = undefined;
      return;
    }

    const { videoDuration } = videoData;

    const cueLength = videoDuration / mBeatCount;
    const cues: Cue[] = [];
    for (let i = 0; i < mBeatCount; i += 1) {
      cues.push({
        startTimeStamp: i * cueLength,
        duration: undefined
      });
    }

    this.channelSequence = new ChannelSequence(cues);
  }

  get videoData(): VideoData | undefined {
    return this.mVideoData;
  }

  public getActiveCue(globalBeatNumber: number): Cue | null {
    if (!this.channelSequence) {
      return null;
    }

    return this.channelSequence.getActiveCue(globalBeatNumber);
  }

  public getActivePlayEvent(globalBeatNumber: number): PlayEvent | null {
    const activeCue = this.getActiveCue(globalBeatNumber);
    if (!activeCue || !this.videoData) {
      return null;
    }
    return {
      videoId: this.videoData.videoId,
      channelNumber: this.channelNumber,
      startTimeStamp: activeCue.startTimeStamp,
      duration: activeCue.duration
    };
  }
}
