import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";

const Sessions = () => {
  const [session, setSession] = useState("");
  const [channel, setChannel] = useState("1");

  const navigate = useNavigate();
  const location = useLocation();

  const handleChangeSession = (event: React.ChangeEvent<HTMLElement>) => {
    setSession((event.target as HTMLInputElement).value);
  };

  const handleChangeChannel = (event: React.ChangeEvent<HTMLElement>) => {
    setChannel((event.target as HTMLInputElement).value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(`${location.pathname}/${session}/${channel}`);
  };
  return (
    <div style={{ overflow: "hidden" }}>
      <h2>Sessions</h2>
      <form onSubmit={handleSubmit}>
        Session:
        <input
          type="text"
          name="session"
          value={session}
          onChange={handleChangeSession}
        />
        <select name="channel" value={channel} onChange={handleChangeChannel}>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
        <input type="submit" value="Submit" />
      </form>
    </div>
  );
};

export default Sessions;
