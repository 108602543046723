import React from "react";
import { Outlet } from "react-router";

interface LayoutProps {}

const Layout: React.FunctionComponent<LayoutProps> = () => (
  <div>
    <Outlet />
  </div>
);

export default Layout;
