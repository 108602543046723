import React from "react";
import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import Sequencer from "../components/sequencer/Sequencer";
import styles from "./SequencerPage.module.css";

interface SequencerPageProps {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}

const SequencerPage: React.FC<SequencerPageProps> = props => {
  const { socket } = props;
  return (
    <div className={styles.mainContainer}>
      <Sequencer socket={socket} />
    </div>
  );
};

export default SequencerPage;
