export function empty<TValue>(
  value: TValue | null | undefined
): value is null | undefined {
  return value === null || value === undefined;
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return !empty(value);
}
