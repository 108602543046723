import { makeAutoObservable } from "mobx";
import type Cue from "../cue/Cue";

export default class ChannelSequenceCell {
  public isActive = false;

  constructor(public readonly cue: Cue) {
    makeAutoObservable(this);
  }

  public setActive(isActive: boolean): void {
    this.isActive = isActive;
  }

  public setStartTimeStamp(timeStamp: number): void {
    this.cue.startTimeStamp = timeStamp;
  }
}
