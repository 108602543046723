import AbstractActionStrategy from "./AbstractActionStrategy";
import type Channel from "../../channel/Channel";
import { DEFAULT_CELL_KEYMAP } from "../../DefaultValues";

export default class RecordLiveStrategy extends AbstractActionStrategy {
  constructor() {
    super();

    for (const key of DEFAULT_CELL_KEYMAP) {
      this.addCellAction(key);
    }
  }

  private addCellAction(keyCode: string): void {
    this.keyActionMap.set(keyCode, (activeChannel, globalBeatCount) =>
      this.handleChannelCellAction(
        activeChannel,
        globalBeatCount,
        DEFAULT_CELL_KEYMAP.indexOf(keyCode)
      )
    );
  }

  private handleChannelCellAction = (
    activeChannel: Channel,
    globalBeatNumber: number,
    triggeredCellIndex: number
  ): void => {
    const { channelSequence } = activeChannel;

    if (!channelSequence) {
      // no video chosen
      return;
    }

    const { cells } = channelSequence;
    const cellCount = cells.length;
    if (cellCount <= triggeredCellIndex) {
      // triggered cell index beyond cell count
      return;
    }

    const sourceCell = cells[triggeredCellIndex];
    const { startTimeStamp } = sourceCell.cue;

    const targetCell = cells[globalBeatNumber % cellCount];
    targetCell.setStartTimeStamp(startTimeStamp);
    targetCell.setActive(true);
  };
}
