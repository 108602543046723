import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import io from "socket.io-client";
import Layout from "./components/Layout";
import Sessions from "./pages/sessions/Sessions";
import NoMatch from "./pages/NoMatchPage";
import SequencerPage from "./pages/SequencerPage";
import Session from "./pages/sessions/Session";
import SessionsPage from "./pages/SessionsPage";
import IndexPage from "./pages/IndexPage";

let ENDPOINT;

if (process.env.NODE_ENV === "production") {
  ENDPOINT = process.env.WS_URL;
} else if (process.env.NODE_ENV === "development") {
  ENDPOINT = "http://127.0.0.1:3001";
}

const socket = io(ENDPOINT);

const App = () => {
  //   const [data, setData] = useState(null);
  const [, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    socket.on("connect", () => {
      setIsConnected(true);
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  //   useEffect(() => {
  //     fetch('/api')
  //       .then((res) => res.json())
  //       .then((data) => setData(data.message));
  //   }, []);

  return (
    <div className="App" style={{ margin: 0 }}>
      <header className="App-header">
        {/* <p>{!data ? "Loading..." : data}</p> */}
      </header>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<IndexPage />} />
          <Route path="sequencer" element={<SequencerPage socket={socket} />}>
            <Route
              path=":sequencerId"
              element={<SequencerPage socket={socket} />}
            />
          </Route>
          <Route path="sessions" element={<SessionsPage />}>
            <Route path=":sessionId" element={<Session socket={socket} />} />
            <Route
              path=":sessionId/:channelId"
              element={<Session socket={socket} />}
            />
            <Route index element={<Sessions />} />
          </Route>
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
};

export default App;
