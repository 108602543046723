export const DEFAULT_CHANNEL_COUNT = 4;
export const DEFAULT_BEAT_COUNT = 16;
export const DEFAULT_BPM = 140;
export const DEFAULT_VIDEOS = [
  "o0XPHCXnxUc", // TE KO II
  "G66oaOrIlXs", // Alan Parsons
  "C7HEpdnaTyM", // 909
  "qz54B3Qk8gg", // yts 1 preview
  "VffgG-JWvF8", // akai mpc 60
  "jjTuHlb9s54", // making the most of micro
  "fgG-QiChiA8", // Drexciya
  "IEz4ar4y_aM", // timelords reportage
  "gsfT_5OQJSI", // all you need to know 80s synth
  "r28i-k3mL3o", // suzanne ciani
  "m_yMf_Ra9XM", // dhruba ghosh
  "cu38AL4E7I4", // sampling documentary
  "pxwr2CaYZcM", // nodir umarov
  "jLGQ1OqT3a4", // sturm der liebe
  "iJeSnSFrEcM", // yamaha dx 100 ad
  "dql9X67fKdQ", // asik veysel guiar
  "a4eav7dFvc8" // prodigy outer space
];
export const DEFAULT_CELL_KEYMAP = [
  "1",
  "2",
  "3",
  "4",
  "q",
  "w",
  "e",
  "r",
  "a",
  "s",
  "d",
  "f",
  "y",
  "x",
  "c",
  "v"
];
