import React from "react";
import styles from "./HelpDialog.module.css";
import YtsLogo from "../art/YtsLogo";

interface HelpDialogProps {}

const HelpDialog: React.FunctionComponent<HelpDialogProps> = () => {
  // Example positions (you will need to adjust these based on your UI layout)
  const descriptionPosition = { x: 100, y: 50 }; // Position of the description box
  const targetPositionPads = { x: 300, y: 300 }; // Position of the target UI element
  const targetPositionVideo = { x: 600, y: 280 }; // Position of the target UI element

  return (
    <div className={styles.dialog}>
      <div>
        <div>
          {/* PAD Description */}
          <svg className={styles.svgOverlay}>
            <line
              x1={descriptionPosition.x + 16}
              y1={descriptionPosition.y + 64}
              x2={targetPositionPads.x}
              y2={targetPositionPads.y}
              stroke="white"
              strokeWidth="2"
            />
          </svg>
          <div
            className={styles.description}
            style={{
              top: descriptionPosition.y + 32,
              left: descriptionPosition.x
            }}
          >
            <div className={styles.descriptionText}>
              <h4>Pads</h4>
              <p>
                Play them like MPC Drum Pads. Sample start of each pad is
                calculated by Video duration / 16. Sample Start of each Pad can
                be adjusted while holding specific Pad and turning BIG DIAL.
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* VIDEO Description */}
          <svg className={styles.svgOverlay}>
            <line
              x1={descriptionPosition.x + 400}
              y1={descriptionPosition.y + 64}
              x2={targetPositionVideo.x}
              y2={targetPositionVideo.y}
              stroke="white"
              strokeWidth="2"
            />
          </svg>
          <div
            className={styles.description}
            style={{
              top: descriptionPosition.y + 32,
              left: descriptionPosition.x + 400
            }}
          >
            <div className={styles.descriptionText}>
              <h4>Video</h4>
              <p>
                See and listen to the output. Enter a YouTube Video Id and load
                a video of your choice or start with a preselected one by
                pressing VID +
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* Box Descriptions */}
          <svg className={styles.svgOverlay}>
            <line
              x1={1216}
              y1={210}
              x2={1190}
              y2={320}
              stroke="white"
              strokeWidth="2"
            />
          </svg>
          <div
            className={styles.description}
            style={{
              top: 80,
              left: 1190
            }}
          >
            <div className={styles.descriptionText}>
              <h4>STEP EDIT and RECORD</h4>
              <p>
                Record has two modes. 1. Performance: If Sequencer is playing
                (PLAY), Pads get recorded as you play them. 2. Step-Edit: If
                Sequencer is stopped (STOP) Pads can be activated, on the 4x4
                grid. If the Sequencer is then started active Pads will play at
                their respective sample start.
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* Transport Descriptions */}
          <svg className={styles.svgOverlay}>
            <line
              x1={1016}
              y1={510}
              x2={920}
              y2={620}
              stroke="white"
              strokeWidth="2"
            />
          </svg>
          <div
            className={styles.description}
            style={{
              top: 620,
              left: 890
            }}
          >
            <div className={styles.descriptionText}>
              <h4>Channel and Video Selection</h4>
              <p>
                Press VID + and VID - for cycling through the preselected
                videos. Press CHAN + and CHAN - for cycling through Channel 1 -
                4.
              </p>
            </div>
          </div>
        </div>
        <div>
          {/* General Descriptions */}
          <div
            className={styles.description}
            style={{
              top: 500,
              left: 200
            }}
          >
            <div className={styles.descriptionWelcome}>
              <h4>Welcome to yts-1</h4>
              <p>
                Start by playing around with 4x4 Pads!
                <br />
                <br />
                TIP 1: Press the lonesome Button in the lower center of the
                Controler to RESET everything.
                <br />
                <br />
                TIP 2: Hold or keep pressing a Pad Button and simultanously turn
                the BIG DIAL for changing the Start Time Stamp of the Pad /
                Sample.
                <br />
                <br />
                TIP 3: Turn the Video Fullscreen for even more fun by pressing
                100% SIZE.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.logoBR}>
            <YtsLogo />
          </div>
          <div className={styles.logoBL}>
            <YtsLogo />
          </div>
          <div className={styles.logoTR}>
            <YtsLogo />
          </div>
          <div className={styles.logoTL}>
            <YtsLogo />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HelpDialog;
