import React, { useState } from "react";
import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import { observer } from "mobx-react";
import SequencerModel from "../../model/root/SequencerModel";
import PlaybackController from "../../model/play/PlaybackController";
import ChannelUI from "./ChannelUI";
import { handlePlayEvents } from "../../model/misc/handlePlayEvents";
import { useKeyActionMapper } from "../../functions/hook/useKeyActionMapper";
import SequencerUIState from "../../model/control/SequencerUIState";
import ClockGenerator from "../../model/clock/ClockGenerator";

interface SequencerProps {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}

const Sequencer: React.FunctionComponent<SequencerProps> = props => {
  const { socket } = props;
  // const { sequencerId } = useParams();

  const [clockGenerator] = useState<ClockGenerator>(() => new ClockGenerator());
  const [sequencerModel] = useState<SequencerModel>(() => {
    const model = new SequencerModel();
    model.createDefaultChannels();
    return model;
  });
  const [playbackController] = useState<PlaybackController>(() => {
    const controller = new PlaybackController(clockGenerator, events =>
      handlePlayEvents(events, socket)
    );
    controller.setActiveModel(sequencerModel);
    return controller;
  });

  // useEffect(() => {
  //   return () => {
  //     playbackController.dispose();
  //   };
  // }, []);

  const [uiState] = useState<SequencerUIState>(
    () =>
      new SequencerUIState(
        sequencerModel,
        clockGenerator,
        playbackController,
        socket
      )
  );

  const activeChannel = uiState.getActiveChannel();

  useKeyActionMapper(uiState);

  return (
    <>
      {sequencerModel.channels.map(channel => (
        <div
          key={channel.channelNumber}
          style={{ display: channel === activeChannel ? "block" : "none" }}
        >
          <ChannelUI
            socket={socket}
            channel={channel}
            uiState={uiState}
            // sequencerId={sequencerId}
          />
        </div>
      ))}
    </>
  );
};

export default observer(Sequencer);
