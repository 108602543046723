import { makeAutoObservable } from "mobx";
import type { SubscriptionDisposer } from "../clock/ClockGenerator";
import type SequencerModel from "../root/SequencerModel";
import type { PlayEvent } from "./PlayEvent";
import type ClockGenerator from "../clock/ClockGenerator";

export default class PlaybackController {
  private mActiveModel: SequencerModel | undefined;

  private clockDisposable: SubscriptionDisposer;

  constructor(
    private readonly clockGenerator: ClockGenerator,
    private readonly eventCallback: (events: PlayEvent[]) => void
  ) {
    makeAutoObservable(this);
    this.clockDisposable = clockGenerator.subscribeClock(this.handleTick);
  }

  public setActiveModel(model: SequencerModel | undefined): void {
    this.mActiveModel = model;
    this.setRunning(false);
  }

  get isRunning(): boolean {
    return this.clockGenerator.isRunning;
  }

  public async setRunning(isRunning: boolean): Promise<void> {
    return this.clockGenerator.setRunning(isRunning);
  }

  public setBpm(bpm: number): void {
    this.clockGenerator.setBpm(bpm);
  }

  get bpm(): number {
    return this.clockGenerator.bpm;
  }

  public isPlaybackAvailable(): boolean {
    return !!this.mActiveModel;
  }

  public dispose(): void {
    this.clockDisposable();
  }

  private handleTick = (globalBeatCount: number) => {
    if (!this.mActiveModel) {
      return;
    }

    const events = this.mActiveModel.getPlayEvents(globalBeatCount);
    this.eventCallback(events);
  };
}
