import { Base64 } from "js-base64";

// RegEx videoId:    [0-9A-Za-z_-]{10}[048AEIMQUYcgkosw]

export function tryRandomYoutubeId() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = 8;
  for (let i = 0; i < charactersLength; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return Base64.encode(result, true);
}

// export function getValidRandomYoutubeId() {
//   const tryString = tryRandomYoutubeId();
//   validaVideoId("Yg4VzVXKhAk");
// }

export function validaVideoId(input: string) {
  // console.log(input);
  fetch(`/api/validate/${input}`, {
    headers: { "Content-Type": "text/plain" }
  }).then(response => response.json());
  // .then(data => console.log(data));
}
