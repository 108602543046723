/* eslint-disable no-unused-expressions */
import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import ReactPlayer from "react-player";

export type SocketData = {
  videoId: string;
  startTimeStamp: number;
  channel: string;
};

interface SessionProps {
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}

const Session: React.FunctionComponent<SessionProps> = props => {
  const { socket } = props;
  const ref = React.useRef(null);
  const playerRef = useRef<ReactPlayer>(null);
  const { channelId } = useParams();

  const [playing, setPlaying] = useState(false);
  const [, setPlayed] = useState(0);
  const [volume] = useState(0.8);
  const [url, setUrl] = useState("");

  const buildUrl = (url: string) => `https://youtube.com/watch?v=${url}`;

  if (!channelId) {
    return <>choose Channel Id!</>;
  }

  const onMessage = (data: SocketData) => {
    setUrl(data.videoId);
    buildUrl(url);
    setPlayed(data.startTimeStamp);
    playerRef.current?.seekTo(data.startTimeStamp);
    !playing && setPlaying(true);
  };

  socket.on(channelId.toString(), onMessage);

  return (
    <div ref={ref} style={{ overflow: "hidden" }}>
      <ReactPlayer
        style={{ margin: 0 }}
        height={window.innerHeight}
        width={window.innerWidth}
        ref={playerRef}
        url={buildUrl(url)}
        playing={playing}
        volume={volume}
      />
    </div>
  );
};

export default Session;
