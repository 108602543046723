import type { IObservableArray } from "mobx";
import { observable } from "mobx";
import { DEFAULT_BEAT_COUNT, DEFAULT_CHANNEL_COUNT } from "../DefaultValues";
import Channel from "../channel/Channel";
import type { PlayEvent } from "../play/PlayEvent";
import { notEmpty } from "../misc/notEmpty";
import { assertBeatCountValid } from "../misc/assertBeatCountValid";

export default class SequencerModel {
  private mBeatCount: number = DEFAULT_BEAT_COUNT;

  private mChannels: IObservableArray<Channel> = observable([]);

  public createDefaultChannels() {
    const newChannels = [];

    for (let i = 0; i < DEFAULT_CHANNEL_COUNT; i += 1) {
      newChannels.push(new Channel(i + 1, this.mBeatCount));
    }
    this.mChannels.replace(newChannels);
  }

  get channels(): Channel[] {
    return this.mChannels;
  }

  public createChannel(): void {
    this.mChannels.push(
      new Channel(this.mChannels.length + 1, this.mBeatCount)
    );
  }

  public deleteChannel(channel: Channel): void {
    this.mChannels.remove(channel);
  }

  /**
   * Provides all play events to send for the given beat number
   * @param beatNumber
   */
  public getPlayEvents(beatNumber: number): PlayEvent[] {
    return this.mChannels
      .map(channel => channel.getActivePlayEvent(beatNumber))
      .filter(notEmpty);
  }

  public setBeatCount(beatCount: number) {
    assertBeatCountValid(beatCount);

    this.mBeatCount = beatCount;
    for (const channel of this.channels) {
      channel.setBeatCount(beatCount);
    }
  }

  get beatCount(): number {
    return this.mBeatCount;
  }
}
