import React from "react";
import { tryRandomYoutubeId } from "src/functions/getRandomVideo";
import { Link } from "react-router-dom";
import YtsLogo from "src/components/art/YtsLogo";
import styles from "./IndexPage.module.css";

interface IndexPageProps {}

const IndexPage: React.FC<IndexPageProps> = () => {
  const id = tryRandomYoutubeId();
  return (
    <div className={styles.mainContainer}>
      <div>
        <div style={{ display: "table", margin: "auto" }}>
          <img src="img/yts1-bk-logo.png" width="150px" alt="yts" />
        </div>
        <p style={{ maxWidth: "600px" }}>
          yts-1 is a web-based sampler and sequencer for YouTube that lets you
          blend and remix content in realtime. <br />
          <br />
          Load videos of choice into individual channel sequencers. Start a
          session, play the videos live or create patterns.
        </p>
      </div>
      <div>
        <Link to={`/sequencer/${id}`}>
          <img src="img/yts1-tr.webp" width="100%" alt="yts" />
        </Link>
      </div>
      <p style={{ maxWidth: "600px", marginTop: "64px" }}>
        by{" "}
        <a target="_blank" href="https://maxwolfs.com" rel="noreferrer">
          maxwolfs
        </a>
      </p>
      <div>
        <div className={styles.logoBR}>
          <YtsLogo variant="black" />
        </div>
        <div className={styles.logoBL}>
          <YtsLogo variant="black" />
        </div>
        <div className={styles.logoTR}>
          <YtsLogo variant="black" />
        </div>
        <div className={styles.logoTL}>
          <YtsLogo variant="black" />
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
