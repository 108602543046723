import * as Tone from "tone";
import type { IObservableArray } from "mobx";
import { makeAutoObservable, observable } from "mobx";
import { DEFAULT_BPM } from "../DefaultValues";

export type ClockSubscriber = (globalBeatNumber: number) => void;
export type SubscriptionDisposer = () => void;
export default class ClockGenerator {
  private mBpm: number = DEFAULT_BPM;

  private mIsRunning = false;

  private isInitialized = false;

  private mGlobalBeatNumber = 0;

  private subscribers: IObservableArray<ClockSubscriber> = observable([]);

  constructor() {
    makeAutoObservable(this);
  }

  public reset(): void {
    this.mGlobalBeatNumber = 0;
  }

  public async setRunning(isRunning: boolean) {
    if (this.mIsRunning === isRunning) {
      return;
    }
    await this.initialize();
    if (!this.mIsRunning) {
      // Tone.js Transport as wrapper for Web Audio Context Clock Wrapper
      Tone.Transport.bpm.value = this.mBpm;
      Tone.Transport.start();
    } else {
      Tone.Transport.stop();
      this.reset();
    }

    this.mIsRunning = isRunning;
  }

  private async initialize() {
    if (this.isInitialized) {
      return;
    }
    this.isInitialized = true;
    await Tone.start();
    Tone.Transport.scheduleRepeat(this.handleTick, "8n");
  }

  get isRunning(): boolean {
    return this.mIsRunning;
  }

  public setBpm(bpm: number): void {
    this.mBpm = bpm;
    Tone.Transport.bpm.value = this.mBpm;
  }

  get bpm(): number {
    return this.mBpm;
  }

  get globalBeatNumber(): number {
    return this.mGlobalBeatNumber;
  }

  public subscribeClock(subscriber: ClockSubscriber): SubscriptionDisposer {
    this.subscribers.push(subscriber);
    return () => this.subscribers.remove(subscriber);
  }

  private handleTick = () => {
    const subscribers = this.subscribers.slice();

    for (const subscriber of subscribers) {
      subscriber(this.mGlobalBeatNumber);
    }
    this.mGlobalBeatNumber =
      (this.mGlobalBeatNumber + 1) % Number.MAX_SAFE_INTEGER;
  };
}
