import type { IObservableArray } from "mobx";
import { makeAutoObservable, observable } from "mobx";
import type Cue from "../cue/Cue";
import ChannelSequenceCell from "./ChannelSequenceCell";
import { cloneCue } from "../misc/cloneCue";
import { assertBeatCountValid } from "../misc/assertBeatCountValid";

export default class ChannelSequence {
  private mCells: IObservableArray<ChannelSequenceCell> = observable([]);

  constructor(cues: Cue[]) {
    this.mCells.replace(cues.map(cue => new ChannelSequenceCell(cue)));
    makeAutoObservable(this);
  }

  get cells(): ChannelSequenceCell[] {
    return this.mCells;
  }

  public getActiveCue(globalBeatNumber: number): Cue | null {
    const cellAtBeat = this.mCells[globalBeatNumber % this.mCells.length];
    return cellAtBeat.isActive ? cellAtBeat.cue : null;
  }

  updateBeatCount(beatCount: number) {
    assertBeatCountValid(beatCount);

    const { mCells } = this;
    const currentCellCount = mCells.length;
    if (currentCellCount === beatCount) {
      return;
    }

    if (currentCellCount > beatCount) {
      this.mCells.replace(this.mCells.slice(0, beatCount));
      return;
    }

    const pointsToAdd = beatCount - currentCellCount;

    // Clone last cue and append n-times (n = pointsToAdd)
    const cueToClone = this.mCells[currentCellCount - 1].cue;

    for (let i = 0; i < pointsToAdd; i += 1) {
      this.mCells.push(new ChannelSequenceCell(cloneCue(cueToClone)));
    }
  }
}
