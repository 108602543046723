import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import AbstractActionStrategy from "./AbstractActionStrategy";
import { DEFAULT_CELL_KEYMAP } from "../../DefaultValues";
import type Channel from "../../channel/Channel";

export default class LiveStrategy extends AbstractActionStrategy {
  public socket: Socket<DefaultEventsMap, DefaultEventsMap> | undefined;

  constructor() {
    super();

    for (const key of DEFAULT_CELL_KEYMAP) {
      this.addCellAction(key);
    }
  }

  private addCellAction(keyCode: string): void {
    this.keyActionMap.set(keyCode, activeChannel =>
      this.handleChannelCellAction(
        activeChannel,
        DEFAULT_CELL_KEYMAP.indexOf(keyCode)
      )
    );
  }

  private handleChannelCellAction = (
    activeChannel: Channel,
    triggeredCellIndex: number
  ): void => {
    const { channelSequence } = activeChannel;

    if (!channelSequence || !this.socket) {
      // no video chosen
      return;
    }

    const { cells } = channelSequence;
    const cellCount = cells.length;
    if (cellCount <= triggeredCellIndex) {
      // triggered cell index beyond cell count
      return;
    }

    const sourceCell = cells[triggeredCellIndex];

    const { videoData } = activeChannel;

    if (!videoData) {
      return;
    }

    this.socket.emit(activeChannel.channelNumber.toString(), {
      startTimeStamp: sourceCell.cue.startTimeStamp,
      videoId: videoData.videoId
    });
  };
}
