import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import type { PlayEvent } from "../play/PlayEvent";

export function handlePlayEvents(
  events: PlayEvent[],
  socket: Socket<DefaultEventsMap, DefaultEventsMap>
) {
  // console.log(events);
  events.map(playEvent => {
    return socket.emit(playEvent.channelNumber.toString(), {
      startTimeStamp: playEvent.startTimeStamp,
      videoId: playEvent.videoId
    });
  });
}
