import { observer } from "mobx-react";
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player";
import { mod } from "src/functions/mod";
import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import type { SocketData } from "src/pages/sessions/Session";
import type Channel from "../../model/channel/Channel";
import styles from "./ChannelUI.module.css";
import ChannelSequenceCellUI from "./ChannelSequenceCellUI";
import { buildYoutubeVideoUrl } from "../../model/misc/buildYoutubeVideoUrl";
import { DEFAULT_CELL_KEYMAP, DEFAULT_VIDEOS } from "../../model/DefaultValues";
import type SequencerUIState from "../../model/control/SequencerUIState";
import ChannelSequenceControlUI from "./ChannelSequenceControlUI";
import TitleAndChildren from "../misc/TitleAndChildren";
import Button from "../misc/Button";
import HelpDialog from "./HelpDialog";
import YtsLogo from "../art/YtsLogo";

interface Props {
  channel: Channel;
  uiState: SequencerUIState;
  // sequencerId: string;
  socket: Socket<DefaultEventsMap, DefaultEventsMap>;
}

const ChannelUI: React.FunctionComponent<Props> = props => {
  const { channel, uiState, socket } = props;
  const { channelSequence } = channel;
  const videoListLength = DEFAULT_VIDEOS.length;

  const [videoUrl, setVideoUrl] = useState<string>();
  const [videoListIndexCursor, setVideoListIndexCursor] = useState(1);
  // const [shareDialogVisible, setShareDialogVisible] = useState<boolean>();
  const [helpDialogVisible, setHelpDialogVisible] = useState<boolean>();
  const [videoLoadTriggered, setVideoLoadTriggered] = useState<boolean>(false);
  const [videoFullscreen, setVideoFullscreen] = useState<boolean>(false);
  const [volume] = useState(80);

  const [playing, setPlaying] = useState(false);
  const [, setPlayed] = useState(0);

  // auto load first video
  // useEffect(() => {
  //   loadNextVideo();
  // }, []);

  const playerRef = useRef<ReactPlayer>(null);

  const amIActive = channel === uiState.getActiveChannel();

  const handleDurationCalculated = (duration: number) => {
    channel.setVideo({ videoId: videoUrl, videoDuration: duration });
  };

  const handleRemove = () => {
    channel.channelSequence = undefined;
    setVideoLoadTriggered(false);
  };

  const handleFullscreenVideo = () => {
    setVideoFullscreen(!videoFullscreen);
  };

  // stop individual youtube players / video
  // const togglePlayState = () => {
  //   setPlaying(!playing);
  // };

  // const handleShare = () => {
  //   setShareDialogVisible(!shareDialogVisible);
  // };

  const handleHelp = () => {
    setHelpDialogVisible(!helpDialogVisible);
  };

  const loadPreviousVideo = () => {
    const newCursor = videoListIndexCursor - 1;
    setVideoListIndexCursor(newCursor);
    setVideoUrl(DEFAULT_VIDEOS[mod(newCursor, videoListLength)]);
    setVideoLoadTriggered(true);
  };

  const loadNextVideo = () => {
    const newCursor = videoListIndexCursor + 1;
    setVideoListIndexCursor(newCursor);
    setVideoUrl(DEFAULT_VIDEOS[mod(newCursor, videoListLength)]);
    setVideoLoadTriggered(true);
  };

  const onMessage = (data: SocketData) => {
    setPlayed(data.startTimeStamp);
    playerRef.current?.seekTo(data.startTimeStamp);
    if (!playing) {
      setPlaying(true);
    }
  };

  socket.on(channel.channelNumber.toString(), onMessage);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.channelUIContainer}>
        <div className={styles.container}>
          <TitleAndChildren title="Channel Pads" />
          <div className={styles.cellBox}>
            {!!channelSequence &&
              channelSequence.cells.map((cell, index) => (
                <ChannelSequenceCellUI
                  uiState={uiState}
                  channel={channel}
                  keycode={DEFAULT_CELL_KEYMAP[index]}
                  cell={cell}
                  key={DEFAULT_CELL_KEYMAP[index]}
                  videoDuration={channel.videoData?.videoDuration}
                  index={index}
                />
              ))}
            {!channelSequence &&
              DEFAULT_CELL_KEYMAP.map(index => (
                <Button
                  disabled
                  variant="cell"
                  title={DEFAULT_CELL_KEYMAP[index]}
                  key={index}
                />
              ))}
          </div>
        </div>

        <div className={styles.container}>
          <TitleAndChildren title={`Channel ${channel.channelNumber}`} />
          {videoLoadTriggered ? (
            <div
              className={
                !videoFullscreen ? styles.notfullscreen : styles.fullscreen
              }
            >
              <ReactPlayer
                ref={playerRef}
                height={!videoFullscreen ? "224px" : "100vh"}
                width={!videoFullscreen ? "400px" : "100vw"}
                url={buildYoutubeVideoUrl(videoUrl)}
                onDuration={handleDurationCalculated}
                playing={playing}
                volume={volume}
              />
            </div>
          ) : (
            <div style={{ width: "400px", height: "224px" }}>
              <input
                className={styles.input}
                onChange={event => {
                  setVideoLoadTriggered(false);
                  setVideoUrl(event.target.value);
                }}
                type="text"
                placeholder="Enter Video Id"
              />
              <Button
                variant="default"
                title="Load"
                disabled={!videoUrl || videoLoadTriggered}
                handleClick={() => setVideoLoadTriggered(true)}
              />
            </div>
          )}
        </div>
        {amIActive && (
          <ChannelSequenceControlUI
            className={styles.container}
            channel={channel}
            uiState={uiState}
            handleRemove={handleRemove}
            // handleShare={handleShare}
            handleHelp={handleHelp}
            handleNextVideo={loadNextVideo}
            handlePreviousVideo={loadPreviousVideo}
            handleFullscreenVideo={handleFullscreenVideo}
          />
        )}
        {/* {amIActive && channelSequence && (
          <div className={styles.container}>
            <TitleAndChildren title="Information" />
            <p>
              Active Channel: {uiState.activeChannelIndex + 1} of{" "}
              {uiState.getTotalChannels()}
            </p>{" "}
            <p>Video Id: {channel.videoData?.videoId}</p>
            <p>
              {uiState.isRecording ? "Record Mode: ON" : "Record Mode: OFF"}
            </p>
            <p>
              Selected Cell Start: {uiState.focusedCell?.cue.startTimeStamp}
            </p>
            <WebSocketLog
              socket={socket}
              channelId={channel.channelNumber.toString()}
            />
          </div>
        )} */}
      </div>
      {/* {shareDialogVisible && (
        <ShareDialog
          shareUrl={sequencerId}
          channelNumber={channel.channelNumber}
        />
      )} */}
      {helpDialogVisible && <HelpDialog />}
      <div>
        <div className={styles.logoBR}>
          <YtsLogo />
        </div>
        <div className={styles.logoBL}>
          <YtsLogo />
        </div>
        <div className={styles.logoTR}>
          <YtsLogo />
        </div>
        <div className={styles.logoTL}>
          <YtsLogo />
        </div>
      </div>
    </div>
  );
};
export default observer(ChannelUI);
