import { makeAutoObservable } from "mobx";
import type { Socket } from "socket.io-client";
import type { DefaultEventsMap } from "socket.io/dist/typed-events";
import type Channel from "../channel/Channel";
import type SequencerModel from "../root/SequencerModel";
import type { Action } from "./strategy/AbstractActionStrategy";
import type AbstractActionStrategy from "./strategy/AbstractActionStrategy";
import LiveStrategy from "./strategy/LiveStrategy";
import RecordLiveStrategy from "./strategy/RecordLiveStrategy";
import RecordStrategy from "./strategy/RecordStrategy";
import type ClockGenerator from "../clock/ClockGenerator";
import type PlaybackController from "../play/PlaybackController";
import type ChannelSequenceCell from "../sequence/ChannelSequenceCell";

export default class SequencerUIState {
  private liveStrategy = new LiveStrategy();

  private recordStrategy = new RecordStrategy();

  private recordLiveStrategy = new RecordLiveStrategy();

  protected keyActionMap = new Map<string, Action>();

  public isRecording = false;

  public activeChannelIndex = 0;

  public focusedCell: ChannelSequenceCell | undefined;

  constructor(
    private sequencerModel: SequencerModel,
    private clockGenerator: ClockGenerator,
    private playbackController: PlaybackController,
    private socket: Socket<DefaultEventsMap, DefaultEventsMap>
  ) {
    makeAutoObservable(this);
    this.liveStrategy.socket = socket;
    this.defineActions();
  }

  private defineActions(): void {
    this.keyActionMap.set("9", this.toggleRecord);
    this.keyActionMap.set("ArrowRight", () => this.changeActiveChannel(1));
    this.keyActionMap.set("ArrowLeft", () => this.changeActiveChannel(-1));
  }

  public hasKeyAction(keycode: string): boolean {
    if (this.keyActionMap.has(keycode)) {
      return true;
    }
    return this.getActionStrategy().hasKeyAction(keycode);
  }

  public getPlaybackController(): PlaybackController {
    return this.playbackController;
  }

  public runKeyAction(keycode: string): void {
    const action = this.keyActionMap.get(keycode);
    const activeChannel = this.getActiveChannel();
    if (action) {
      action(activeChannel, this.clockGenerator.globalBeatNumber);
      return;
    }
    // eslint-disable-next-line consistent-return
    return this.getActionStrategy().runKeyAction(
      keycode,
      activeChannel,
      this.clockGenerator.globalBeatNumber
    );
  }

  public getGlobalBeatNumber(): number {
    return this.clockGenerator.globalBeatNumber;
  }

  public getTotalChannels(): number {
    return this.sequencerModel.channels.length;
  }

  public getActiveCellIndexForChannel(channel: Channel): number {
    return this.clockGenerator.globalBeatNumber % channel.getBeatCount();
  }

  public getActiveChannel(): Channel {
    const { activeChannelIndex } = this;
    return this.sequencerModel.channels[activeChannelIndex];
  }

  private getActionStrategy(): AbstractActionStrategy {
    if (this.isRecording) {
      if (this.playbackController.isRunning) {
        return this.recordLiveStrategy;
      }
      return this.recordStrategy;
    }
    return this.liveStrategy;
  }

  // actions
  private toggleRecord = () => {
    this.isRecording = !this.isRecording;
  };

  private changeActiveChannel = (delta: number) => {
    const channelCount = this.sequencerModel.channels.length;
    this.activeChannelIndex = (this.activeChannelIndex + delta) % channelCount;
    if (this.activeChannelIndex < 0) {
      this.activeChannelIndex = channelCount - 1;
    }
    // console.log(this.activeChannelIndex);
  };
}
