import type Channel from "../../channel/Channel";

export type Action = (activeChannel: Channel, globalBeatCount: number) => void;

export default abstract class AbstractActionStrategy {
  protected keyActionMap = new Map<string, Action>();

  public hasKeyAction(keycode: string): boolean {
    return this.keyActionMap.has(keycode);
  }

  public runKeyAction(
    keycode: string,
    activeChannel: Channel,
    globalBeatCount: number
  ): void {
    const action = this.keyActionMap.get(keycode);
    if (action) {
      action(activeChannel, globalBeatCount);
    }
  }
}
