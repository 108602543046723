import React from "react";
import styles from "./YtsLogo.module.css";

interface LogoProps {
  variant?: string;
}

const YtsLogo: React.FunctionComponent<LogoProps> = props => {
  const { variant } = props;
  return (
    <div className={styles.logo}>
      {variant === "black" ? (
        <img src="../img/yts1-bk-logo.png" width="150px" alt="yts" />
      ) : (
        <img src="../img/yts1-wh-logo.png" width="150px" alt="yts" />
      )}
    </div>
  );
};

export default YtsLogo;
