import { makeObservable, observable } from "mobx";

export default class Cue {
  constructor(
    public startTimeStamp: number,
    public duration: number | undefined
  ) {
    makeObservable(this, {
      startTimeStamp: observable,
      duration: observable
    });
  }
}
