import type { CSSProperties } from "react";
import React from "react";

interface TitleAndChildrenProps {
  children?: React.ReactNode;
  title: string;
  style?: CSSProperties;
}

const TitleAndChildren: React.FC<TitleAndChildrenProps> = props => {
  const { children, title, style } = props;
  return (
    <div style={style}>
      <h5>{title}</h5>
      {children}
    </div>
  );
};

export default TitleAndChildren;
