import type { CSSProperties } from "react";
import React from "react";
import styles from "./Button.module.css";

interface ButtonProps {
  title: string;
  handleClick?: () => void;
  variant: "default" | "cell";
  isActive?: boolean;
  isPlaying?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  timestamp?: number;
}
const Button: React.FunctionComponent<ButtonProps> = props => {
  const {
    title,
    handleClick,
    variant,
    isActive,
    isPlaying,
    style,
    disabled,
    timestamp
  } = props;

  if (variant === "default") {
    return (
      <button
        disabled={disabled}
        className={styles.default}
        style={style}
        type="submit"
        onClick={handleClick}
      >
        {title}
      </button>
    );
  }
  if (variant === "cell") {
    return (
      <button
        disabled={disabled}
        className={`${styles.cell} + ${isActive ? styles.active : ""} + ${
          isPlaying ? styles.isPlaying : ""
        }`}
        style={style}
        type="submit"
        onClick={handleClick}
      >
        {title} <br /> <p> {timestamp}</p>
      </button>
    );
  }
  return <>no variant set</>;
};
export default Button;
