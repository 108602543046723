import { observer } from "mobx-react";
import React from "react";
import type SequencerUIState from "src/model/control/SequencerUIState";
import { useKeyPressEvent } from "react-use";
import type Channel from "src/model/channel/Channel";
import styles from "./ChannelSequenceControlUI.module.css";
import Button from "../misc/Button";
import TitleAndChildren from "../misc/TitleAndChildren";

interface Props {
  uiState: SequencerUIState;
  channel: Channel;
  className?: string;
  handleRemove: () => void;
  // handleShare: () => void;
  handleHelp: () => void;
  handleNextVideo: () => void;
  handlePreviousVideo: () => void;
  handleFullscreenVideo: () => void;
}

const ChannelSequenceControlUI: React.FunctionComponent<Props> = props => {
  const {
    uiState,
    channel,
    className,
    handleRemove,
    handleHelp,
    handleNextVideo,
    handlePreviousVideo,
    handleFullscreenVideo
  } = props;

  const playbackController = uiState.getPlaybackController();

  const togglePlay = () => {
    playbackController.setRunning(!playbackController.isRunning);
  };

  const incrementBpm = () => {
    const { bpm } = playbackController;
    const newBpm = bpm + 1;
    playbackController.setBpm(parseFloat(newBpm.toFixed(2)));
  };
  const decrementBpm = () => {
    const { bpm } = playbackController;
    const newBpm = bpm - 1;
    playbackController.setBpm(parseFloat(newBpm.toFixed(2)));
  };

  const incrementBeatcount = () => {
    const beatcount = channel.getBeatCount();
    const newBeacount = beatcount + 1;
    channel.setBeatCount(newBeacount);
  };
  const decrementBeatcount = () => {
    const beatcount = channel.getBeatCount();
    const newBeacount = beatcount - 1;
    channel.setBeatCount(newBeacount);
  };

  const handleReset = () => {
    window.location.reload();
  };

  useKeyPressEvent(".", incrementBpm);
  useKeyPressEvent(",", decrementBpm);
  useKeyPressEvent("i", decrementBeatcount);
  useKeyPressEvent("o", incrementBeatcount);
  useKeyPressEvent("7", togglePlay);
  useKeyPressEvent("8", handleHelp);
  useKeyPressEvent("u", handleFullscreenVideo);
  useKeyPressEvent("ArrowUp", handlePreviousVideo);
  useKeyPressEvent("ArrowDown", handleNextVideo);
  useKeyPressEvent("Shift", handleReset);

  return (
    <div className={className}>
      <TitleAndChildren title={`Clock ${playbackController.bpm} BPM`} />
      <div className={styles.container}>
        <Button
          isPlaying={playbackController.isRunning}
          variant="cell"
          title={playbackController.isRunning ? "STOP ⏹️" : "PLAY ▶️"}
          handleClick={togglePlay}
        />
        <Button
          variant="cell"
          title="HINT"
          handleClick={handleHelp}
          style={{ background: "#e4540c", border: "solid 2px #e4540c" }}
        />
        <Button
          isPlaying={uiState.isRecording}
          variant="cell"
          title={playbackController.isRunning ? "REC ⏺" : "STEP EDIT"}
        />
        {/* <Button variant="cell" title="href" handleClick={handleShare} /> */}
        <Button
          variant="cell"
          title="100% SIZE"
          handleClick={handleFullscreenVideo}
        />
        <Button
          variant="cell"
          title="STEP -"
          handleClick={incrementBeatcount}
        />
        <Button
          variant="cell"
          title="STEP +"
          handleClick={decrementBeatcount}
        />
        <div style={{ width: "48px", height: "48px" }} />
        <Button variant="cell" title="VID +" handleClick={handleNextVideo} />
        <div style={{ width: "48px", height: "48px" }} />
        <Button variant="cell" title="CHAN -" handleClick={handleRemove} />
        <Button
          variant="cell"
          title="VID -"
          handleClick={handlePreviousVideo}
        />
        <Button variant="cell" title="CHAN +" />
      </div>
    </div>
  );
};
export default observer(ChannelSequenceControlUI);
